



























































import Vue from "vue";
import Component from "vue-class-component";
import { diceRoll, selectDiceResults } from "./dice";

import ShortcutDialog from "./ShortcutDialog.vue";

interface Dice {
  faces: number;
  value: number;
}

@Component({
  components: {
    ShortcutDialog
  }
})
export default class Tool extends Vue {
  $refs!: {
    commandField: HTMLInputElement;
  };

  data() {
    return {
      command: "",
      help: false,
      edit: false,
      snackbar: false,
      errorMsg: ""
    };
  }

  clearForm() {
    this.$data.command = "";
  }

  get gameType() {
    return this.$store.state.gameType;
  }

  get gameName() {
    return this.$store.state.gameName;
  }

  get gameInfo() {
    return this.$store.state.gameInfo.trim().replace(/\n/g, "<br />");
  }

  get shortcuts() {
    return this.$store.state.shortcuts;
  }

  get showSystemInfo() {
    return this.$store.state.settings.showSystemInfo;
  }

  execShortcut(command: string) {
    if (command.match(/[?？]/)) {
      this.$data.command = command;
      this.$refs.commandField.focus();
    } else {
      this.dicerollByText(command);
    }
  }

  diceroll() {
    this.dicerollByText(this.$data.command, true);
  }

  dicerollByText(text: string, clear = false) {
    diceRoll(this.gameType, text)
      .then(res => {
        const log: Log = {
          gameType: this.gameType,
          command: text,
          body: res.text,
          drawables: selectDiceResults(res.rands),
          timestamp: new Date(),
          success: res.success,
          failure: res.failure
        };

        if (clear) {
          this.clearForm();
        }

        this.$store.commit("appendLogBuffer", log);
      })
      .catch(error => {
        if (error.response) {
          this.$data.errorMsg = "ダイスコマンドを実行できませんでした";
        } else {
          this.$data.errorMsg = "APIサーバーに接続できませんでした";
        }
        this.$data.snackbar = true;
      });
  }

  beforeEnter(el: HTMLElement) {
    el.style.height = "0";
  }
  enter(el: HTMLElement) {
    el.style.height = el.scrollHeight + "px";
  }
  beforeLeave(el: HTMLElement) {
    el.style.height = el.scrollHeight + "px";
  }
  leave(el: HTMLElement) {
    el.style.height = "0";
  }
}
