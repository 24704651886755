








































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { DEFAULT_URL } from "./dice";
import { COMMIT_HASH, STAGED, UNSTAGED } from "./constants";

@Component({
  props: {
    value: Boolean
  },
  watch: {
    value(val: boolean) {
      if (val != this.$data.isActive) {
        this.$data.isActive = val;
      }
    },
    isActive(val: boolean) {
      this.$emit("input", val);
    }
  }
})
export default class Settings extends Vue {
  data() {
    return {
      isActive: false,
      snackbar: false,
      snackbarText: "",
      removeLogDialog: false
    };
  }

  resetApiURL() {
    this.$store.dispatch("updateApiURL", DEFAULT_URL);
    this.$data.snackbarText = `APIをデフォルト設定に戻しました`;
    this.$data.snackbar = true;
  }

  removeAllLogs() {
    this.$store.commit("removeAllLogs");
    this.$data.removeLogDialog = false;

    this.$data.snackbarText = `ログを削除しました`;
    this.$data.snackbar = true;
  }

  get diceBots() {
    return this.$store.state.diceBots;
  }

  get commitHash() {
    if (STAGED || UNSTAGED) {
      return COMMIT_HASH + "+";
    } else {
      return COMMIT_HASH;
    }
  }

  get apiURL() {
    return this.$store.state.apiURL;
  }

  set apiURL(newURL: string) {
    this.$store.dispatch("updateApiURL", newURL);
    this.$data.snackbarText = `変更しました`;
    this.$data.snackbar = true;
  }

  get gameType() {
    return this.$store.state.gameType;
  }

  set gameType(newType) {
    this.$store.dispatch("updateGameType", newType);
    this.$data.snackbarText = `ダイスボットを「${newType}」に変更しました`;
    this.$data.snackbar = true;
  }

  get playSound() {
    return this.$store.state.settings.playSound;
  }
  set playSound(val: boolean) {
    this.$store.commit("updateSoundSetting", val);
  }

  get playDiceAnimation() {
    return this.$store.state.settings.playDiceAnimation;
  }
  set playDiceAnimation(val: boolean) {
    this.$store.commit("updateAnimationSetting", val);
  }

  get showSystemInfo() {
    return this.$store.state.settings.showSystemInfo;
  }
  set showSystemInfo(val: boolean) {
    this.$store.commit("updateSystemInfoSetting", val);
  }

  get showTips() {
    return this.$store.state.settings.showTips;
  }
  set showTips(val: boolean) {
    this.$store.commit("updateTipsSetting", val);
  }

  get volume() {
    return this.$store.state.settings.soundVolume * 10;
  }

  set volume(val: number) {
    const newVolume = val / 10;
    this.$store.commit("updateSoundVolumeSetting", newVolume);
  }
}
