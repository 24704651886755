










import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: {
    face: Number,
    value: Number
  }
})
export default class Dice extends Vue {
  get diceClass() {
    return "dice-" + this.$props.face;
  }
  get valueClass() {
    return "value-" + this.$props.value;
  }
  get isActive() {
    return this.$store.state.activeAnimation;
  }

  hide() {
    this.$emit("hide");
  }
}
