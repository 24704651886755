

































import Vue from "vue";
import Component from "vue-class-component";
@Component({
  props: {
    value: Boolean
  },
  watch: {
    value(val: boolean) {
      if (val != this.$data.isActive) {
        this.$data.isActive = val;
      }
    },
    isActive(val: boolean) {
      this.$emit("input", val);
    }
  }
})
export default class ShortcutDialog extends Vue {
  data() {
    return {
      isActive: false,
      command: ""
    };
  }

  add() {
    this.$store.commit("addShortcut", this.$data.command);
    this.$data.command = "";
  }

  remove(command: string) {
    this.$store.commit("removeShortcut", command);
  }

  get shortcuts() {
    return this.$store.state.shortcuts;
  }
}
