




















import Vue from "vue";
import Component from "vue-class-component";

import AppBar from "./AppBar.vue";
import Log from "./Log.vue";
import Tool from "./Tool.vue";
import Tips from "./Tips.vue";
import DiceArea from "./DiceArea.vue";
@Component({
  components: {
    AppBar,
    Tool,
    Log,
    DiceArea,
    Tips
  }
})
export default class App extends Vue {
  mounted() {
    this.$store.dispatch("initialize");
    this.$ga.page("/");
  }
}
