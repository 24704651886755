































import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Tool extends Vue {
  get showTips() {
    return this.$store.state.settings.showTips;
  }
}
