






































import Vue from "vue";
import Component from "vue-class-component";
import { diceRoll, selectDiceResults } from "./dice";
import { Log as LogI } from "./interface";

interface Dice {
  faces: number;
  value: number;
}

const aDay = 1000 * 60 * 60 * 24;
const baseTime = Date.now();

@Component
export default class Log extends Vue {
  data() {
    return {
      snackbar: false,
      errorMsg: ""
    };
  }

  get logs() {
    return this.$store.state.logs;
  }

  format(dateOrg: string | Date): string {
    const date = new Date(dateOrg);
    const diff = baseTime - date.getTime();
    const h = this.zeroPadding(date.getHours());
    const m = this.zeroPadding(date.getMinutes());
    if (diff >= aDay) {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${month}月${day}日 ${h}:${m}`;
    } else {
      return `${h}:${m}`;
    }
  }

  zeroPadding(x: number): string {
    return ("0" + x).slice(-2);
  }

  dicerollByText(gameType: string, text: string) {
    diceRoll(gameType, text)
      .then(res => {
        const log: LogI = {
          gameType: gameType,
          command: text,
          body: res.text,
          drawables: selectDiceResults(res.rands),
          timestamp: new Date(),
          success: res.success,
          failure: res.failure
        };

        this.$store.commit("appendLogBuffer", log);
      })
      .catch(error => {
        if (error.response) {
          this.$data.errorMsg = "ダイスコマンドを実行できませんでした";
        } else {
          this.$data.errorMsg = "APIサーバーに接続できませんでした";
        }
        this.$data.snackbar = true;
      });
  }
}
