














































import Vue from "vue";
import Component from "vue-class-component";
import Settings from "./Settings.vue";

interface DiceBotInfo {
  name: string;
  gameType: string;
}

@Component({
  components: {
    Settings
  }
})
export default class AppBar extends Vue {
  data() {
    return {
      settings: false
    };
  }

  get diceBots() {
    return this.$store.state.diceBots;
  }

  get gameType() {
    return this.$store.state.gameType;
  }

  set gameType(newType) {
    this.$store.dispatch("updateGameType", newType);
    // this.$data.snackbarText = `ダイスボットを「${newType}」に変更しました`;
    // this.$data.snackbar = true;
  }

  get playSound() {
    return this.$store.state.settings.playSound;
  }
  set playSound(val: boolean) {
    this.$store.commit("updateSoundSetting", val);
  }
}
